var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delete-message-action-view"},[_c('punish-message',{model:{value:(_vm.model.message),callback:function ($$v) {_vm.$set(_vm.model, "message", $$v)},expression:"model.message"}}),_c('time-unit-input',{staticClass:"mb-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'delete_message_delay',
        'settingWrapper': {
          customiseValue: _vm.StepUnit.Minute,
          hasFieldButton: true,
          disabledValue: 0,
        }
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }